import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../components/Card';
import { ButtonGhost } from '../../../components/Button';
import ImageWrap from '../../../components/ImageWrap';
import { designTokens } from '../../../components/Theme/designTokens';
import projects from '../../../data/projects';
import { IconBox, IconCard } from '../../../components/IconBox';
import { Box, ProjectBox } from '../../../components/Box';
import { CallToAction } from '../../../components/CallToAction';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="SoleVenture" mdxType="SEO" />
    <IntroProject projectName="SoleVenture" projectDescription="The back-office platform for your company of one" projectCaption="Giving freelancers the security of steady income and traditional benefits" projectYear="2019 - 2020" projectImage="soleventure-preview" mdxType="IntroProject" />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h4>Overview 📋</h4>
        <p className="lead"><a href="https://www.soleventure.com/">SoleVenture</a> makes insurance benefits and invoicing more accessible for freelancers by combining a business manager, insurance marketplace, company formation, accounting automation, and reporting all in one place.</p>
        <p>I worked with SoleVenture as their sole designer, establishing their brand identity and crafting an end-to-end user experience for a native mobile app, and later a web app. Today, SoleVenture is one of the fastest growing benefits platforms for freelancers and is helping new and old freelancers more meaningfully navigate the gig economy.</p>
        <IconCard cardTitle="My Role" cardText="Branding, UI/UX, Front-end development" mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
        </IconCard>
        <IconCard cardTitle="Specfications" cardText="Help freelancers manage the back-office of their freelance business" mdxType="IconCard">
          <MdFlag fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdFlag" />
        </IconCard>
        <ProjectBox image="soleventure-example-icon" title="We're live!" description="Download the app for iOS or Android" link="https://www.soleventure.com/launch-and-grow" mdxType="ProjectBox" />
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Introduction</h5>
        <p className="lead">The easiest way to setup a business, access benefits, manage freelance income, and track business contracts.</p>
        <p>When I first started working with SoleVenture, we focused on making it easier for freelancers to setup and manage their business. Most freelancers use a variety of apps/services in order to manage simple projects within their freelance business.</p>
        <p>This can easily be a logistical nightmare, as freelancers might lapse in a certain area of their business when life gets in the way:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <ul>
            <li>losing a contract document</li>
            <li>forgetting to add an invoice under the business</li>
            <li>missing business insurance</li>
          </ul>
        </Box>
        <p>As a result, many freelancers end up either overspending for separate services or dissuaded from freelancing altogether. SoleVenture helps freelancers manage their business by giving them a mobile and web dashboard to help manage the accounting and insurance for their small business.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAAB6klEQVQ4y22TW5KbMBBFvf81JPlPOVlDfmYFU5W/jJ/xYIx5SUJghLjp2xiKcoWqRuh1dK+62VjbIL067HdXHI9nVJXF7ZbL9wVFUaOunY5VldNgf4rmOW61b0yj7cZaJ8AGH38SnE5nHUzTDLvdHnleIoSIvg94PBi9hnNeQEbHONe4doFuqPCaOAGmKMtaBr1uGIaIGAdYa9G2rfQHDT6cr2srIIeuf8j8Yw30uKY5PnZ/cb9XOsgNXMTTkyQRpblCGXyappXwiHJAG3pY38KsFWZZKXaTBeh9h66bgPfsLsor+e4VNo4TkGNcw6d7VZjeCuz3F3wm2aKQi0MIsnxAHPvnAQN8ExSY54Uo7hTIdgHyVZYWR1F4OH4+s2qkreXSB1xOAXk23V2MoygcFZiJci9W6YKOXoC12qZlTnADLXof8PYrw+/3WkCDKJ7ABNHydBVB1PdaUiugEWChUNYVT+RmZrkPRqLRJMxJ4ZWwZg+MwwksvaUOZ6lzsD/fIU92UmOsxRijlhIt09F2+wPftz/x5es3nM8XOXCyvZkhc7DPgk7Tm2ws1brCwvCszahXMgtgUmeGWl7/NsZMvxRLqRCYMUYVdUOA6bslMW71Z7zG5tXu9O3kPjNVqHUmKu+S+f8B187Y/gPvEuWoSP+6wQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dashboard",
          "title": "Dashboard",
          "src": "/static/3c33e11fefe122bff2a0d7e3ac93ea37/d9199/sv-1.png",
          "srcSet": ["/static/3c33e11fefe122bff2a0d7e3ac93ea37/8ff5a/sv-1.png 240w", "/static/3c33e11fefe122bff2a0d7e3ac93ea37/e85cb/sv-1.png 480w", "/static/3c33e11fefe122bff2a0d7e3ac93ea37/d9199/sv-1.png 960w", "/static/3c33e11fefe122bff2a0d7e3ac93ea37/07a9c/sv-1.png 1440w", "/static/3c33e11fefe122bff2a0d7e3ac93ea37/29114/sv-1.png 1920w", "/static/3c33e11fefe122bff2a0d7e3ac93ea37/6fc98/sv-1.png 2161w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACK0lEQVQoz5WQ60uTURzH/S/qVYgWOe3iy154iwJ7FYEW3sg1wqmbuVleMCjyxrxtGr3RSqrXRf+EBosuBIHNuU2T4aa7PO5xj9ue+Xw6ezQIamUHPodzDr/f53zPKSgxVJLDUFpFUfEFaq800NHZT5vZhrWrT8diPcBq7RX7Abru3Mdk6qO5xSL6KjldUqEderSCn8LSshpOFJ7HZJxlYU5mdsrPi6dRnVcLMUGUl88SPHH5cE185PF0GMfI5/zCEkM1xafKuVjloMe6jb3Lj9Xsp7szQGvjKq0Nq5iNbgYHXjM6NYfZtET91Xeir+LPQkNpNUUny6m9PMG9uyHsdi82mw+7zY+lw0+HaYX37mXSmV32URl+8I3GusX8CX8V9tsi2EWyHss6tvY1utvXMTZ4efvGQzarsCsrDPYu01S/9Bfh4ZMv1YzTdjOMsdHLrSafjql5lZb6NYYffmAztEYwGMNy+ys3ri3qvXkTFhaVc71uGqdjh7FHGziGgoyPbOo4hkK4JgM8n//ExNgXJkfD9Pe48wvLztRw7Pg5nM45ckNVVTRNI5lUSCoKqVRKrFMoSY2sqpfg8fiOIHQdCLPZrJg1JGmHjY0ggcB3QuGwuCgtzvf1mpUV39ETZjIq0k6CWFxiaztKKBTR15FIDGVv7/8TZjKZQ1RS4slyPE4qnSYtyJ0dJPT/S3iWmZl5vViWd4lLEoqQKckkkkiXkGWx39PJ/bHX+7vwB0xAW797VaoJAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Homepage",
          "title": "Homepage",
          "src": "/static/f2e90039377bb46af504773936dd6e04/d9199/sv-landing-1.png",
          "srcSet": ["/static/f2e90039377bb46af504773936dd6e04/8ff5a/sv-landing-1.png 240w", "/static/f2e90039377bb46af504773936dd6e04/e85cb/sv-landing-1.png 480w", "/static/f2e90039377bb46af504773936dd6e04/d9199/sv-landing-1.png 960w", "/static/f2e90039377bb46af504773936dd6e04/07a9c/sv-landing-1.png 1440w", "/static/f2e90039377bb46af504773936dd6e04/29114/sv-landing-1.png 1920w", "/static/f2e90039377bb46af504773936dd6e04/38116/sv-landing-1.png 2086w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABuvAAAbrwFeGpEcAAABrklEQVQoz32S227bMAyG+/4vNaDAsJuiW7OmRbY0sRPHdhofdIhlWbL7l5LsZMiSXhAUKZKgvl93bb2FqjaYvKri0TYXcYx2jNv6XDP2fThP9x93/jAN5RmsPpI10GLv86aVZBQfS1/TNTV005BnUGGIq7sysIxgW4bFi8Xvn5YGcHQyQ5r0eHowaGQLIxOUB4Mf9xpVYdCJdNry2sAYRlWIVj3WyyFswlMUhwHLRY+2Uej4FqzqMP9lwWtD97uA5XKgX712fBI0PA9GZ1Vt0bAcRxfXu/A8dyYcivCcuV8OHOE2tGUePSNbP0Me1j5XbF+RrmZg+RKaJRD7N+zeZuA+HntvisJ2GDTD0DH/XJezhGHQxNNtRcqa4wEwAh35k8pfMUw2veeoHUORoSx6xCsbGIoEdUkMnwykODP8UuU/r5YaLIkSVN5nA2aPhgZqr3LxbvD9W1BZixsDp//kwCtJ/4u+jGIpCUM5XpEonMTZ+2YtS/pCHC35qe9/lV0hAa/Sv8iiOfL4BTVBl0VEIs29FckCmmrF+woJieL8if1NUbwPNuX/tanmFF/Z8BNR8IorqkqGNAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Features",
          "title": "Features",
          "src": "/static/c7a4b83483ba238abe5d58f343ad4cdd/d9199/sv-intro-1.png",
          "srcSet": ["/static/c7a4b83483ba238abe5d58f343ad4cdd/8ff5a/sv-intro-1.png 240w", "/static/c7a4b83483ba238abe5d58f343ad4cdd/e85cb/sv-intro-1.png 480w", "/static/c7a4b83483ba238abe5d58f343ad4cdd/d9199/sv-intro-1.png 960w", "/static/c7a4b83483ba238abe5d58f343ad4cdd/07a9c/sv-intro-1.png 1440w", "/static/c7a4b83483ba238abe5d58f343ad4cdd/56e36/sv-intro-1.png 1750w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Onboarding" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Setting up a Business 💼</h5>
        <p>Onboarding new freelancers and setting up a new business can become fairly complex. Our goal was to make this process more transparent and simple - letting various other behind-the-scenes tasks perform the operational tasks involved when registering a new business.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAACcklEQVQ4y42Sy27TQBSG+4DwJGzYUsGGB4AFmwqpC0ACqQIhoQpVoG4qoaJeQps6IU2aNnGcxPexx7exnczPzNitLUElLH2aY8+cf85/fDYsh2DhCVwCkmcIihRmUL0rvAp5zrED2HJ1gja8HW8Yvo9u6uGSh9ja+YZHj7ew37/GaB1CYz60TOwzD1Piw/1fwQuR1C8I3u0d4smzXTzd7OLtlw726BhHkYleTlqCwT8FbZtUgjMheJa5OBf0VhRaEeLzcR8PHm7i/cEPDEDRSR3ciHOOKazbPizLVwI1vBYTsRC0SIh5kWC5SjB0KYY2xQ118WFnH9p0BhsMRhHDpiHCMAQhMYIgge9HcN1A0rJM+EYQRACHeka9NfrdOYaXPUz1CbI0udtLY0A7i6HrU4zH16JKG2W5wnrNeZJkqmplWQpyXmXRKMZgcInJZIqrqzFCSlFviapSDHouDENXgrquC8FSbvE0ZTBNrxIkJFIJjDFomoaj40OxdnBy8hPn593bJCwWBi60X0JsjNFohNPTU8RxfL9gnhdwPUfYXaJzPIc+CUV/HFEZFYmptKaqXa3WKpbUsbJsmi3L6pra2sH3HC+eB9j9mNRWRfO9QK2SKErVBbckCeOUxk0PCaEoilIhq0zTBsYKyH0pJG3JSiqaOMtyIZg0gq15+gt5qE5SlxWFoGxI4hwRLXgYtATrSW8QwykHVMZyaEXlshk8zznfeUP59quAv35J+NdPsfouyTLGxU/ht4K4D3krY7nKK8s1FrMCwz7D6DeDtSxE39eqz9VP8e6psK5MrkKQG4bFZzNTYVoWt+yKpdl8n8/tu5w/54Fi4EmBoiAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Login/Sign Up",
          "title": "Login/Sign Up",
          "src": "/static/02dddf3f723b6f1d09570ae0e6546262/d9199/sv-onboard-1.png",
          "srcSet": ["/static/02dddf3f723b6f1d09570ae0e6546262/8ff5a/sv-onboard-1.png 240w", "/static/02dddf3f723b6f1d09570ae0e6546262/e85cb/sv-onboard-1.png 480w", "/static/02dddf3f723b6f1d09570ae0e6546262/d9199/sv-onboard-1.png 960w", "/static/02dddf3f723b6f1d09570ae0e6546262/07a9c/sv-onboard-1.png 1440w", "/static/02dddf3f723b6f1d09570ae0e6546262/09ede/sv-onboard-1.png 1710w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABCcAAAQnAEmzTo0AAACTUlEQVQoz22TS47TQBCGfQ0ktgwsERyEBWLFY8sGEEggzYIrIKQRcAnuwAqJHQLEoEmIkgzJJH4ltvvdbjs/Ve3MCASWSuVud/9d9fXv5M3r93jy8BUOn77F4bN3ePH4CM8fHWE8miNPA0bHFt4HONdSBFjroaRBrTVyqXHy3aGuPK1poZRBcvfWSxxcuo2b1x7gxsF9XL9yD1cv38Gnj99oc4v5LEOWZcjzHGmaoqoqaGXRdh2kMTidFVguU5Rlib7fIfnya4mvoxkmo1NMJ2eYT9cxVlkDpQ0JbFAU5T4KGJrb1IaqsbDGwlgNKSUMvXddj8QUGYIW6PoOwA7nj9IO260gQRnb5Wo5e+8hqbW6ViSkI4LhuydRh0RvGxj6oGmgScQYT9kSJw1nHVpicx7OWizmipgZCEm5lpEbrx+CBBvnkGuJwjT4cdxgNhEIwaPcamSVRtEYlMKilBarQmE8lshKhW01iLVtuIi+D0j6voe2BilBXyzWxGkbW5YkUFNFVeuQSYFM1VhmBpOToWXupKOL2e0GTPyepy21THCd8xEoT3IOoYund22LQLEpN9jQpRitImfeo6KoG1qlMfNcrxS13EgqX0QeTaMibM4FcbJNDUvVGWIZQoi2CHSg2l8Kr/szlNIsqOOAT5JsWDrAOruH7WMWgkTJcxcOoDneI4T+K1gr4U0MlFmE0EfHi7qLDNkq/Jdo+ivYf5Un1kbQDev/CnIkltphEfYSC3Ol86kmD5JtyHPcqqd5T5fwOVvgw9lPVELuK/pX9DeqWIW9BbnIIwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Onboarding",
          "title": "Onboarding",
          "src": "/static/a55b68f97c70d1d1ed3e1c7fb49c4893/d9199/sv-onboard-2.png",
          "srcSet": ["/static/a55b68f97c70d1d1ed3e1c7fb49c4893/8ff5a/sv-onboard-2.png 240w", "/static/a55b68f97c70d1d1ed3e1c7fb49c4893/e85cb/sv-onboard-2.png 480w", "/static/a55b68f97c70d1d1ed3e1c7fb49c4893/d9199/sv-onboard-2.png 960w", "/static/a55b68f97c70d1d1ed3e1c7fb49c4893/07a9c/sv-onboard-2.png 1440w", "/static/a55b68f97c70d1d1ed3e1c7fb49c4893/29114/sv-onboard-2.png 1920w", "/static/a55b68f97c70d1d1ed3e1c7fb49c4893/3930f/sv-onboard-2.png 2805w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.08333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAABUklEQVQ4y5WT20rDQBCG+/6v5Y0oYrSIFMULbWmaU5vN7Cmn35mNW6ot2Az8ZJgdvp3DZqGUhqiuKSj6ShGqivDxXmOzSbFeb7FLcz47z42+aBGdU7B8iTSq0iC5r7FcviBJnrFavXHcoGl+557qItD7FuM4BmlLSNMURVGi63oMwxDi3nfXA9u2g5h3HlQWyLIceV5Aa3O8THJmVSg2dh2G7ItnWTEwZ8gUF5sFjBW2DHQ/vphSCsbY+cDYVt/3sNbCORcgRHSs8ipgTNDaYpeVOBwa3iihLPcM9WEpApILnWvnAYviEIDiW8vLIRuejCjG/r7Biy0rfrgyp7qeYFHGuADVIu1OgFdUKFVIhbEiiQtEwN4bDKPj1v28pfT9EGYmX5G1js8tXpcGj3cau60Lf9O/LUuCtDcNf1qA+JrHsN8Tnh4ItzcN1p/cidZnbX8DGRPpG1Woe/wAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Payment",
          "title": "Payment",
          "src": "/static/347166765ce9d34f3195080cec8bff34/d9199/sv-payment-1.png",
          "srcSet": ["/static/347166765ce9d34f3195080cec8bff34/8ff5a/sv-payment-1.png 240w", "/static/347166765ce9d34f3195080cec8bff34/e85cb/sv-payment-1.png 480w", "/static/347166765ce9d34f3195080cec8bff34/d9199/sv-payment-1.png 960w", "/static/347166765ce9d34f3195080cec8bff34/07a9c/sv-payment-1.png 1440w", "/static/347166765ce9d34f3195080cec8bff34/e67a8/sv-payment-1.png 1740w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVQoz32Sy2sTURSHCy5caXGhoFJrtaC1PvAvKPgAHxREkdRlQRFqFSqlaMWFXbiqi1J8dOHKhRu3FkV0a8RaLclk0mYySTqZpMnkYWsa03l93pk+wLbphR9n7p253/mdM6eBjIwnV/dihNpsjOKMSimmMK8m1ylBWfHexUVMYMghnHTYu++6esSPDf8Bs2GK6hw/vi/yc6LAjGwyLS0RlWq+YmKvpcRnOmhJkCYzAiitAOVNgJkwFT2LnrbQtb/i0iJzukl+zqGQd5HDeW53D3H31hBv33xh3ljCcybkupn1QC8K+1YxhyJc/pqaJhJJkErl0DSDWg0mJyJ0XR/kzLkeBvqHsaq1rYGuFoJKgWnRv6/BIMHgNzLZLAsLf3CBpKoyW8oTruSYlCXsakXUH/WBbAWcCoX58PET78fHKZXKrC5FUSjoOUrpHKpIimOuOdwcmA7hzud8N6ZpsmRa2LaN4zjLwJkohp4inVCJx+PixFn+KfWAXg/d3zlsQXRWQJ4sy/aBsiShqVGhkHAbA9deA27oIaslV4vUW7ZjkTcMjIJXheuf1S3Z1kSmssLnd+PcufmMgXuj9PeO8KDvOYP3X/BQxEf9r8TzS3/f1zPC8JPXrA70hsE2Z8XEV1OMPB5le0MH+3depmlXJ+3NAU613uDk4S5OHArQsvsKe3dcpHHbWTpOd9cHWh5wMcnY0zH2NXZy/GAXbQcCHGu+RnvrBY60nOdo8yXamgJ+kpY9V+ns6N0A/Afa1jf3+fuELwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Landing Page",
          "title": "Landing Page",
          "src": "/static/6ce4ee413825c1ab3449f62e7567c9e5/d9199/sv-landing-2.png",
          "srcSet": ["/static/6ce4ee413825c1ab3449f62e7567c9e5/8ff5a/sv-landing-2.png 240w", "/static/6ce4ee413825c1ab3449f62e7567c9e5/e85cb/sv-landing-2.png 480w", "/static/6ce4ee413825c1ab3449f62e7567c9e5/d9199/sv-landing-2.png 960w", "/static/6ce4ee413825c1ab3449f62e7567c9e5/07a9c/sv-landing-2.png 1440w", "/static/6ce4ee413825c1ab3449f62e7567c9e5/29114/sv-landing-2.png 1920w", "/static/6ce4ee413825c1ab3449f62e7567c9e5/38116/sv-landing-2.png 2086w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <p>Rather than register in the web application, users may want to register through the mobile app. We needed to collect the same amount of information, yet keep the process away from being too cumborsome for users who may be averse to a lengthier sign up process.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACo0lEQVQoz02T+0uTYRTHX/xJN/sDQgR1a9J+C/ulIGq6DZezTEqMinT4PwSFgmSRWqGlNAdBQSRlP3gBZbaLVFQolqXO29C87OZ8d3n37uLm/PbsvEI9cHjPec57Ps/3PBeuoKAAlZWV0Ol0qKrS0lej0SAvLw9FRUUwGo0wGAzMqsmvqDgNjuOgUqmg11+kGq1WS/+o1WpwSqUS6XQG/49EIoHcQi0tLRQH93iEwmHybTYHAbu7HyMZB0J8DPG4SDmz2SwBk8kkDg8BMZamhCAIBDSZTLSYa2kFCwvL2A3ysFqtR8BusBIsulawtLyKLAssFgu4sjIFg2UgRIA3FgHZg5xCEfn5EjA3fAE/Nnb+kO9wSAq7uroIuLPjIcsyotk8AE6lKofX68H8/BLsk7+xubmNQCAAuVwOU3MzQX6szsA5+xGboXU47XZJYecjyqUSMSSZUcsv+nNAFXw+HxYXFxj0J9zuNYrlhcfQdOs6cBDBt18OOKcnMOf+DNv4MAFb77diGVEIYR4JMUrAvmc94BQKBVIpae8ymcOjPRQhk8lx+2YjmxQR8K7BvT7H5EQwPjJEwLaONqxAQDwWxX4q/g+oVCqYm5vIwSRwOh1hhyKDqYkpxB54nwu818USHtgm3hPwwd07wKqLnX6ICZAUPu/tBVdaUoKRwQ0MvfJjdNCDD693Mfx2DYWF+bha14gvVoHNb2Ps3RYco1EM9EoKO9ruARE/hFAAYniX4Q7Q1/OEtVxagtlPW/huD7JiP2acPKan1gnYUH8DM1P7sI0EYR8L4utkCi/7pT3sfNhOXYl7ATJk92HuewpOxu5bbc15XLmsQa3xAurrNKgxnKOXUlx8HA3XdJSru6Qh/+yZU9JLOaFATbUeeq2GLOerT5bjLzXCc9a8e4uAAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Onboarding Mobile",
          "title": "Onboarding Mobile",
          "src": "/static/858c2bc87c6838e9e871589985436198/d9199/sv-onboard-3.png",
          "srcSet": ["/static/858c2bc87c6838e9e871589985436198/8ff5a/sv-onboard-3.png 240w", "/static/858c2bc87c6838e9e871589985436198/e85cb/sv-onboard-3.png 480w", "/static/858c2bc87c6838e9e871589985436198/d9199/sv-onboard-3.png 960w", "/static/858c2bc87c6838e9e871589985436198/0d1a4/sv-onboard-3.png 1036w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Insurance Marketplace" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Shopping and Managing Insurance ⛑</h5>
        <p>One of the most painstaking processes for small business owners/freelancers usually centers around managing the gamut of insurance plans for their business, such as:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <ul>
            <li>Small Business Insurance</li>
            <li>Life Insurance</li>
            <li>Health Insurance</li>
            <li>Vision</li>
            <li>Retirement</li>
            <li>Dental</li>
          </ul>
        </Box>
        <p>We needed to figure out how users could shop and manage each type of insurance, all within a single platform.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAABu0lEQVQ4y62Ty5KbMBBF/f9fkV0qq6xnlW+YmkmNaxKDeQjzimQQIIHAd7rlsgcn9i5UNU2rxVH3lbTRukNVDgiDAvt9BKVaFEWNND2gbQc0TY/jUT+wjvJna9ve+01LwLrqsA8LxHHqJ2ZZjiDYQ8ojxS3GccKynDDPt2bt5HNdZ67QjdY9VdQgiSsP48HBWAAnOGex2/2mBYS3PD97IQR1kPo5/Bgz3gLzQ4vtNkFZ1mfgMGKaACVngkhkguQIBbZvKYJdQsADSaNonvVA9ivggKqSSJIcdX2kQU5YiMTh+1eJOJjRaSAJZ3z7opCLhdoFLTjh9fXXfWBRSoQht1N5YNNYyHpGFPKGTf77T+UQhwPq0sEMC2k6Q2v9L5BfSmlE8QFRlHkdWZNlAelUkeiz3xBrHd7fU7hp8fE4OsoXj4ANta18y5xgID9SKvp5wenE4pOehfAbweE4jqRl9gjYeh2rWl0r5J+cc74ahvx8Nvjx1ODtxfiYF2IdeTFjVsDLx8U45p3ns9X35urLoodIe9K0vxlnz/NvKryc9vWJ/9v4RnX92d/LX2/KPci64s/KP+1+flXh/7QP2gLiN0bA6E8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Filtering Insurance Options",
          "title": "Filtering Insurance Options",
          "src": "/static/fa464c90092d65c3e3a7b822c498922e/d9199/sv-insurance-1.png",
          "srcSet": ["/static/fa464c90092d65c3e3a7b822c498922e/8ff5a/sv-insurance-1.png 240w", "/static/fa464c90092d65c3e3a7b822c498922e/e85cb/sv-insurance-1.png 480w", "/static/fa464c90092d65c3e3a7b822c498922e/d9199/sv-insurance-1.png 960w", "/static/fa464c90092d65c3e3a7b822c498922e/07a9c/sv-insurance-1.png 1440w", "/static/fa464c90092d65c3e3a7b822c498922e/29114/sv-insurance-1.png 1920w", "/static/fa464c90092d65c3e3a7b822c498922e/b3608/sv-insurance-1.png 2160w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAACEElEQVQ4y3WUW5PSQBCF+fu++mL54m/w0cuzVeK6atUqokhxSdgQyOQ6uUxm0md7GhYE11Q1UzNJfzndp8OoKDSqqkYcl7i/T3jdoyxrJCqT8PfzvJKz/4XWDfmVn6WRAHWNTZhhuYgkuaoa1Nogz8xjAvq+57Cw1sn6GMZYdJ25BGoGhkGG1XKLNC0E0jQdTE9oWyOJ54vw90U0+GeuFLKicBNjvY6gVM5AjWEAgj83KLOtICbLH9Cd5hcZfLj5zvcZxOp+rxScc+Q5DD0Do22CMIyx26Wi0Je2CWdceiFKVK5gHZdnekzma1ZG0qpFEGNwwyVQTEhyrIMtNpvdAegGdCX3rutFocpyOSNr4dJMSi/qDiWHtfZfoO+dV+fB/sxxyu2zF0h+zQX4+v1blKYD9gmKl69E9bu7CM/fTJl9UHjqoQd6dz1MqYMp7BySKOKS9bG8Ugwwhp3Ps0MPWe2OR65n9wToFR7dEUh1nKuc95yNRaiQFrUo/PYlQF0bDHw+D0qwZzCrNerxJ1jCoeSjQm+5PzgFA8nLGo9/0n2kGAGafp6RLhtqeeY+3s78O0jv9xRPpmSH4Qx8avL9zZ5dttwzck7Kr4qc59HIgGep4mocms6i7jqZ05Mp/uc6vEo//ewqMZjKquG9JU6UsHagu681paqltm3o+OlJ3uga9FQLrvc+mrYm/g+4yPXrA4vc4zjvcDgUAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Selecting Insurance",
          "title": "Selecting Insurance",
          "src": "/static/3b808b6e55dcda7f02c4135da70a2b80/d9199/sv-insurance-2.png",
          "srcSet": ["/static/3b808b6e55dcda7f02c4135da70a2b80/8ff5a/sv-insurance-2.png 240w", "/static/3b808b6e55dcda7f02c4135da70a2b80/e85cb/sv-insurance-2.png 480w", "/static/3b808b6e55dcda7f02c4135da70a2b80/d9199/sv-insurance-2.png 960w", "/static/3b808b6e55dcda7f02c4135da70a2b80/07a9c/sv-insurance-2.png 1440w", "/static/3b808b6e55dcda7f02c4135da70a2b80/29114/sv-insurance-2.png 1920w", "/static/3b808b6e55dcda7f02c4135da70a2b80/b3608/sv-insurance-2.png 2160w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <p>After selecting insurance options to add to their account, users are able to track their total spend and manage each type of insurance option added to their account.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAABfElEQVQ4y62T6W7CMBCE8/5PyK8SoOQGx/eRpNP1QmmgRUJqI218ZP15dhwXWhv0ncP2rUVZ7iCEQtP0OBwqKGVfCinNrS0UAYfeoNy22O/fGTgMAnXdcYKU+rbgWWjtbjmF1hZtK3HY9xhHzZPeRzjjKdFjFAExJEzTjJQmxJgopmsknnMu3AObWmGzOaDrBmjlMBqD2kgYSlxmsAIhJJ49WcAK6ND3Z/KsoVJHAlqcR/JRCPgQkT0WND6dRljraZFhRdo6HmdBlqq5A7bdGdvtEVXVM1BQ6U3T0c45UXFpxmSQp9YiThPOkWBKk5gT5aVvYH4JobHb1yh3FUb6kKEtATVBUkpclrUEihHBB8zLAjt7nlNKIYQfQEk7CS5Z0tjQx/pYwdAC5xxDs8IQAqv8mBMWe4Ii9V0rflOo2McMzSdtyJu8ayAPnQ/sWTb+MayJlB/4IKW6Ah//scu/93rkQ7r0LfeLNeTVm3Ev4Kt/gRbra/M38MrD/4xPV+jmhJVZAPoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Managing Insurance",
          "title": "Managing Insurance",
          "src": "/static/c8733e82a6660430d2480b402c578827/d9199/sv-insurance-3.png",
          "srcSet": ["/static/c8733e82a6660430d2480b402c578827/8ff5a/sv-insurance-3.png 240w", "/static/c8733e82a6660430d2480b402c578827/e85cb/sv-insurance-3.png 480w", "/static/c8733e82a6660430d2480b402c578827/d9199/sv-insurance-3.png 960w", "/static/c8733e82a6660430d2480b402c578827/07a9c/sv-insurance-3.png 1440w", "/static/c8733e82a6660430d2480b402c578827/29114/sv-insurance-3.png 1920w", "/static/c8733e82a6660430d2480b402c578827/b3608/sv-insurance-3.png 2160w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Invoices" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Simple Invoice Management 🧾</h5>
        <p>Since most of the app's features center around a freelancer's income, it made sense to allow freelancers to also invoice their clients to the same platform - bringing cycle of income for a freelancer full circle within the SoleVenture app.</p>
        <p>Users are able to create invoices, tie them to clients, and send them to contacts through email.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9UlEQVQ4y1WU+0tTYRjH9xeV0C8VKvaDFITp5nU7l9ZEnbt5R0gsiiIyzRlKUPSL/ZBmiOU0CSWzvIHgpQvSRY+6q8vb3ObSbd+e95w554Ev5+VwzofP+zzvc1QW3g0WM+dBvcGJqusuWrNnXhi1f1BaPA1tzihKKELOOHTlX3BxeAQZ70ZwqW8MYsMvVBX5YBboG2KojoFGrRedzctoNkmo1BGs2IPG2l6MfHCguqYOFUYTHrfZ8arvDdK67cjrWoS+ehlZ/WOwlnhhZlIUVaphleiS1zbRB4NmFe2tg2DXQSSGUDgir/86/bjQ3gbxpoTG7B1kv5xA4f0lVOdvypYqBmI5hrLt2kQvSvMltLU44Pdvw7mxi/1gBOGDGKTVVZx/aofQJOFaxyyuvPgMg/V30vKUoSWhzYAGjQR76xAih3vYC+wjGo3Jhm6nE5kPO5HbtQCx7jusWno/AUvUkIEUmIVzJw3ZlhkwfLSH0L8IYtG4AvRsgM8bQE1RELYCf7J2DJYwVIDmRBjUIm5Cr1mnGjoQPQphPxBENKYYOt1r4NWDsJYSqOwrCXhxvEsWlTlhJR8ViomOS4MgwaZeIuAwQkdUv3AIUATJcB1c3iBsZRIsphmYdT7FkGLifCmGnEsGVvI+3BJ+oDO3F89behGJHiAYDCVr6HKvg9cMwMYHCOZBag9q+ZUTYLV+N2laJdIRUi+ig7Z8cBiQmxKPx1OAb2ET6H3eSd+6YOQ20cwvYE5vhSrrXCvSz9xFbtZraoZfBlqpKXrNBtofDdGW9xAIkeFRooautRMg55TtKgl4m5/HJ30TVKX538CSmfYAVzO6afS2YRU8MKhX8KTdQQgFdHxtbXvBqftPAU3UmBpewh1hDiqrsKlAqHbpZ++hvOinPCls9OptPXg/OoyPE5OYnJzF7OwcenoGIGrGCbgt1z11KJip3GWTjv0UdnD5wjPwOdME3JLrWlGyCn3BFG4UTsNQOEP3KbrPyz8OpRmeU0PB8h9v/yFneXjEQgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mobile Invoices",
          "title": "Mobile Invoices",
          "src": "/static/52a1cba1d87962e0e294990824c4c218/d9199/sv-invoices-3.png",
          "srcSet": ["/static/52a1cba1d87962e0e294990824c4c218/8ff5a/sv-invoices-3.png 240w", "/static/52a1cba1d87962e0e294990824c4c218/e85cb/sv-invoices-3.png 480w", "/static/52a1cba1d87962e0e294990824c4c218/d9199/sv-invoices-3.png 960w", "/static/52a1cba1d87962e0e294990824c4c218/07a9c/sv-invoices-3.png 1440w", "/static/52a1cba1d87962e0e294990824c4c218/29114/sv-invoices-3.png 1920w", "/static/52a1cba1d87962e0e294990824c4c218/88820/sv-invoices-3.png 2564w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAABrElEQVQ4y61Sy1LjMBD0/3/Unjhx2RtFkQ0QF4kTFFu2ZVkPP3p7lJgECFV72MPYM5Kmp7ulzJgO6t3i5fmA19ccWrdQqsJ+r9B1Dk3T3Yy67mCtRYgWxti01rYWWbsAvhyQ5288aAhYst6gqmrEOHzEMFzyaRqxfupx96tN/aY7gWaCejxqFIVKU4VVWWo8PDxitVrD+4BxHBFCZB4TmOTyL1XAfjuSoSc7YU6GxvSUV+HP+i0BywCJRa4w1tqkYVXVpLqRtbJmXnNYoHR3kSxUd1uD1VNBqUeYtuem4WZLoBrOOXg3YYgTG6XJYKR0Zw0JlNwXQH8FSEMP7xU922K3U2eGJ8MFVECCn+nfjL63sCTgQ0A3RtRaJ+kCerkUfuRm87zAZrM7yxR2moxVAnU9yHBm3nC/gY8EiB77okhgff8NsGGzpoT6g6E0ikfdwpCAxphkwYQZlt5VZfkzQ3l7ipcipsuBeZ75NObzf0ohFzhQaqkift9bgnmyc7zA/gK4JEsstXi7rEl+Xcvz0Nre7MuWYomv9a0QS062fO/LboFcT/7X+MTwf8Zfbb3k/s0rCpcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "All Invoices",
          "title": "All Invoices",
          "src": "/static/367ca10b3095e37a94d6d5734a11cff9/d9199/sv-invoices-1.png",
          "srcSet": ["/static/367ca10b3095e37a94d6d5734a11cff9/8ff5a/sv-invoices-1.png 240w", "/static/367ca10b3095e37a94d6d5734a11cff9/e85cb/sv-invoices-1.png 480w", "/static/367ca10b3095e37a94d6d5734a11cff9/d9199/sv-invoices-1.png 960w", "/static/367ca10b3095e37a94d6d5734a11cff9/07a9c/sv-invoices-1.png 1440w", "/static/367ca10b3095e37a94d6d5734a11cff9/29114/sv-invoices-1.png 1920w", "/static/367ca10b3095e37a94d6d5734a11cff9/b3608/sv-invoices-1.png 2160w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAAB5UlEQVQ4y32T327TMBTG+5BwwRuAtK2xncSxk7SNJgF3XEyDSSPQqYwXoEVs8BC9qnrRCy52i1DXf6hT93GOs5RkrYj0xXZi//yd4+OGMQZBYNFs+vA8D1pHiCKDMNSuz61SvvtmjN22Rb8YV/81qkAhhIMUoNC11lqkaYpWq7WVtTEtjpAkSWVcQBv8KlwEtZ3KBY/FgDhOoGkOj7Msc+Ma0PdDHB0JB+bdfD9Ar/cJw+HQaTweYzQaYTKZ4PT0LUUUop22kHU6SGh+XHNIISlpcHio3ETeTQiJ6+vv2PdcXn6GkArCaASJxYEvEZlaDslhoCmH0oVdAr/0B1it1ri9nWM2W2A6neHuboPuxwt4SuGZOsDT5nM8ES+gDOW6CtTaEETRKUsXspQS/f5XrNcbLJcrAv/BfLHEmoHdLqXEdyFbEyON091D4dMMyGWZQwYOBt/IEZy72XyJBYmfi24Pkhy6EI3ZVzb/gCwGep7A1VWRw839Pb2Am+lv/Pj1E+/yHAHVpWFXplqTDw6rhVpKa42Xr14jzz/g/DxH/j7HydkZspM36Bwfw+xZUwu5tF2Ki5lvBzvlfLFCqgAbFoVezdljNerFbGoui2JOtxDux3G8cwVrwF3r9gFg0W639+p/Dv8C9tilGSDv4y8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Add Item",
          "title": "Add Item",
          "src": "/static/072925158ceb764565ae379c4a08cf00/d9199/sv-invoices-2.png",
          "srcSet": ["/static/072925158ceb764565ae379c4a08cf00/8ff5a/sv-invoices-2.png 240w", "/static/072925158ceb764565ae379c4a08cf00/e85cb/sv-invoices-2.png 480w", "/static/072925158ceb764565ae379c4a08cf00/d9199/sv-invoices-2.png 960w", "/static/072925158ceb764565ae379c4a08cf00/07a9c/sv-invoices-2.png 1440w", "/static/072925158ceb764565ae379c4a08cf00/29114/sv-invoices-2.png 1920w", "/static/072925158ceb764565ae379c4a08cf00/b3608/sv-invoices-2.png 2160w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Tax Manager" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Managing Taxes 💰</h5>
        <p>Figuring out what to pay and when to pay it can become a headache for freelancers. Incorporating a way to track quarterly tax statements and make the payment through IRS could help freelancers simplify their business management workflow.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAABsUlEQVQ4y5WS626bQBCF/f5PUal5kkr9WSlSfqZybUOCMQb2foGF09khkDhyqxZp2Nvwzdkz7LS2qM8Gv/ZnHI8FhNC4XFocDgXq+gopLe8JYbaQ0kBJs+3zWlkedxl4qQ32+1ecTiUnXK8dyrKE1hrDMCLGgSOEZdTaoZMKjuY+RBjjNuguv9pWoCgrdJ3kZGs9nE1IExAIOEwz5hnbE+h8bDpAGV5HHyFWYAbUdUsKS1ImeFNRonMBYUzo6GMdEs/nmSoQ+Erqvj5+w8PTd3zZ/0AhOljl3q9cnyV5VhOwZ2C+wjiOSIlABE6kcEoZOHMYKtJ4iyY6VE5BW8eeSlZoLF5KhZ/PNarqspnrvceQFRLcDxPDFyC44ERniCNmKuj9cOthLxRORYXD8ZU7lz3cFPqAgcwcx1tgmqgIFbVtD+viJ2Cn0DT95qGUmkCeBCS0uUmkMHuYaRNd3xEg+MSj80sTb4F9BnYMXRSSKoJk36YYF1Cek6oMNGYBaGqEoYKrTQxcJ2usa06mq+UG5D+B52+x5n2M7cf+fHgv+V58FHFX4d+S78Wf8nb/8vH/xG+JT+ei8P3UPwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tax Manager",
          "title": "Tax Manager",
          "src": "/static/a182e1240fff5c4d78c26fc60f506ee9/d9199/sv-tax-1.png",
          "srcSet": ["/static/a182e1240fff5c4d78c26fc60f506ee9/8ff5a/sv-tax-1.png 240w", "/static/a182e1240fff5c4d78c26fc60f506ee9/e85cb/sv-tax-1.png 480w", "/static/a182e1240fff5c4d78c26fc60f506ee9/d9199/sv-tax-1.png 960w", "/static/a182e1240fff5c4d78c26fc60f506ee9/07a9c/sv-tax-1.png 1440w", "/static/a182e1240fff5c4d78c26fc60f506ee9/29114/sv-tax-1.png 1920w", "/static/a182e1240fff5c4d78c26fc60f506ee9/6fc98/sv-tax-1.png 2161w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.74999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAAB5klEQVQ4y5WTW08TQRiG+SNSw6HdgsYLGrfFFixeGAmJ0gOJlyK98z8QfgiJMfEAJPUQe4OJBvFQFS64gAAxBEHALgu1h93trtnv5ZtlW0tAWzZ5MjPJzpP3+2amRfJHIPBJYQcx7/T2or0jyITQ0Xma6n/HY5iqDkmKUEttUSfuCdxEqHcIwdAQZHkQV+VbzCDk4DFd3f01aUOh13cNmcwbqKqKH1vbKGo6tEoFZR5LpTIMo4Lh2ChaPQEW9zUWinI/fV6A+EzDgKXsw8rtwy5rqH6JZAoXPD1O0nMJLdPEwcYmlPXv0PO/YbvCeGLMTdiMkEv+MJcFiIVFHX90y8HWTU5ZcYRJkbC1iYSi0d1SBJOZ15jaXMKj5Sym1hYxvb6Ix6vf8HAli3fKRl3CBj30svCKvx/j6SdILb3CaHYGDxZeOqS+pnH341NMLL/FcOK+I/Q3eyjzbslGgQ/Csp15rYHiUM7TQ3Ft3s9/gc2SQlGDpokrY0DTDez8LEFRTCRH/t9DqocT0uzsHJmmSap6SPl8gXI5lXZ39+jF9A49f/aLbt+5R56LAfJ39Z3Y6/vXSxGvJDqQwPVojIm7xDBwI+5w6XKUKwnjjL1/E4qFQMz5vVJbu8wEz4RltT1uySTKlXh9BOnvLgnJtFp2AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "All Payment",
          "title": "All Payment",
          "src": "/static/39f5fc70d0d807819b3e327105a3c1a9/d9199/sv-tax-2.png",
          "srcSet": ["/static/39f5fc70d0d807819b3e327105a3c1a9/8ff5a/sv-tax-2.png 240w", "/static/39f5fc70d0d807819b3e327105a3c1a9/e85cb/sv-tax-2.png 480w", "/static/39f5fc70d0d807819b3e327105a3c1a9/d9199/sv-tax-2.png 960w", "/static/39f5fc70d0d807819b3e327105a3c1a9/07a9c/sv-tax-2.png 1440w", "/static/39f5fc70d0d807819b3e327105a3c1a9/34431/sv-tax-2.png 1539w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABh0lEQVQ4y6XUbU/aUBTAcT7wfK/fgy8wX2zLxkMBkS0bD2FopnHxlVaYUG51yGjLpa20IGn/u9RtMWxLQG5ycu6L3t895zRt6ubWYjXMmxHfBw4XepeP1SaZXCkJrVih2TpBmD/+PLd6NvUvbJkNMeTVm7fU6w129/Z4sbNDOp2mfFimdPABy/YTeBVN/a86vW1wUK7we0VRnGTLsshkC9jOPX1zuD7Y7pgUCuqgbTOyLQU4xIsIo9clmysmoNgY1PL4rmTiS8bSYe6rNnud7cDZLMDzPO4VtlyPFRY2B686Imk5cF08OWGxWCSg6D+75UdwLiVREBL/ejmmMLZseSqZhz7zwIP4AeP6cgswnyWcDAjdOwI5IAodulfnzwS/mWgKDBQYuEOm8k6BY671c3L50uag3hYUtRxqiComxLOxmmDAbe+CdxlNgVP6Yk1wmYXK+y/3adYOOT1u8KVV5etJEy37msr7KiNrzU/vaZWXek/9HD5Tqx9RaxzxSe1bx2d/Xf40fgJtN/Q5Lpu3aAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mobile Tax",
          "title": "Mobile Tax",
          "src": "/static/2f0878af971c93504a8b88a7fb3bd880/d9199/sv-tax-3.png",
          "srcSet": ["/static/2f0878af971c93504a8b88a7fb3bd880/8ff5a/sv-tax-3.png 240w", "/static/2f0878af971c93504a8b88a7fb3bd880/e85cb/sv-tax-3.png 480w", "/static/2f0878af971c93504a8b88a7fb3bd880/d9199/sv-tax-3.png 960w", "/static/2f0878af971c93504a8b88a7fb3bd880/07a9c/sv-tax-3.png 1440w", "/static/2f0878af971c93504a8b88a7fb3bd880/29114/sv-tax-3.png 1920w", "/static/2f0878af971c93504a8b88a7fb3bd880/88820/sv-tax-3.png 2564w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Transactions" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Categorizing Expense and Income</h5>
        <p>Freelancers want to look at their business's income as a net rather than separate expense dashboards and paychecks in various platforms. By connecting to a freelancer's bank account, we are able to show and categorize a high-level, categorized view of the net income of the freelancer's small business.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.24999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABCcAAAQnAEmzTo0AAABvUlEQVQoz2VTa2/bMAzM//9JwzDs44B1W5Enig1YNxRpmjiWZT0tybyRfnRuauDAI61cyKO82oQrtrGGxCWktusUtklhzXF98/4GNGnQahbbcTwkjX1qBkjty8sj7k6/8Xj5gwfO9yy853NjHHEYOU1N0GoQ4+JXfcSHvwd8Ov4cIH/wXFeIIaDEiNZYxJSQSoFqzcA75hej8Ww1ifjQ4Tze2lf41p7w3bzghz0PtaYLyH1ByBnaWngW7kqG0gbGeWjjuO5wtub9yEvf7llwyKXuR0jH2+ncyOvxfBzi25G3sy8L/2Yr5prwAQux7cxD/bZDGVd8Ew8/Pj3g8+kX/1DhqNnH6ownRqMquKZCoILICH2GZyTq0faJRHgTFyPfu/PgnYwrXLZ3aRRy7JB4AZ696jn2pYezfojyKGdw8eb9UmY/dgsuXcjTEyGIcC4g5oYFJXc+8KI6NDHQbjGyGPoK8UPioWuo0g2p6kq5S2SNo5ILEatr1XItUyk9ZaWprWsSf189vMW8yToF2NzBMGrv0KYIWxLfPQPlPa7O8sh8bZxZdiifzX9Mn9HYMefrCRupCZf3XT3lC0zX5h/ZNYaLQLLC8QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Reviewing transactions",
          "title": "Reviewing transactions",
          "src": "/static/c6c9b475430ef8f03799106c84965f4b/d9199/sv-transactions-2.png",
          "srcSet": ["/static/c6c9b475430ef8f03799106c84965f4b/8ff5a/sv-transactions-2.png 240w", "/static/c6c9b475430ef8f03799106c84965f4b/e85cb/sv-transactions-2.png 480w", "/static/c6c9b475430ef8f03799106c84965f4b/d9199/sv-transactions-2.png 960w", "/static/c6c9b475430ef8f03799106c84965f4b/07a9c/sv-transactions-2.png 1440w", "/static/c6c9b475430ef8f03799106c84965f4b/9e3ea/sv-transactions-2.png 1503w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABCcAAAQnAEmzTo0AAAB7klEQVQ4y3WUC5OiMBCE/f9/8B7lrqunsiiPPCEQSF9PRM/lXKtSCSH5ZrpncGOtw6X0eH8rsNvt0bYWl2uD8lzA1g208dDawXDOg2u9jMee8ek+b6z1BDrsP0qcTgWUsmgaxYsGcRgxcHjfo+sCQh/gWw3vOmilEWPEPCc416UlgAA7XJnRZ1kx6i0iDxDQYxxjHgINYUAvQMK1sdjudjiezpAf974Ci6LCdvuH4BbybIxjlm2GPENjniM8L1c/3mDLK1KSDFdA8exwOKOq2mdf+KxufnHdtiZnHuMER99dUyNxXdc1z9jFQ5c2znkc9gq/fxU4F5cM4gFedvRTU2aPngABhV5nH50P0N5jpIda6wxa7Eobw6JUV4v9vsTx+JkzkkJ1XZcPi9Sel+d5Jmyi/JlBBmasGVBR8spDyUiqWl7q7OEtkkAt5TQY6OPMAiUCfZgzUCqulMlnpmn6WmUBSqvUtSJYPzL0XqSbnOHIbOdJYCJ9lCJka8T/pSfTzSp6uGrQnKE0u3jYtipX2jqbJXd+YoA5F2fV3P8aeyEvL90DKB5KUQKLEpjRFOndmJhhegBffin3F8+zNK/ICWFk207Y/uzxsWWjx57vuofUl8BnuWvwfU8pl8ddiaYSo/33kr+DvfgT+C/YGvgXy+kz6bnPw00AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Transactions",
          "title": "Transactions",
          "src": "/static/a0e9f82a66adee18529b5558978a728c/d9199/sv-transactions-1.png",
          "srcSet": ["/static/a0e9f82a66adee18529b5558978a728c/8ff5a/sv-transactions-1.png 240w", "/static/a0e9f82a66adee18529b5558978a728c/e85cb/sv-transactions-1.png 480w", "/static/a0e9f82a66adee18529b5558978a728c/d9199/sv-transactions-1.png 960w", "/static/a0e9f82a66adee18529b5558978a728c/07a9c/sv-transactions-1.png 1440w", "/static/a0e9f82a66adee18529b5558978a728c/29114/sv-transactions-1.png 1920w", "/static/a0e9f82a66adee18529b5558978a728c/b3608/sv-transactions-1.png 2160w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Design System" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Scalable, Consistent Design 🎨</h5>
        <p>We worked on crafting a design system early on in the process to:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <span>1. Faster iteration with consistent design</span>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <span>2. Faster development with a reusable set of interface components</span>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <span>3. Clearer, more consistent experience for the user</span>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoklEQVQ4y1VTS2/TQBD23+PEhQMnfgASQlwQB3pACCEuHEEgHhUgKgEXQIU2aQihTdPm2ZKmaaBJE/KqnYcdP+O1vR/rcR3KSGPvzs7szHzfrIRIuCyPkNjI0KZ7vI9mLY9R/xdMdYC5qYYusGwbO/kyOOfQNB2zmQHfDyiePpwzyfM8BDzgodNa4hsFcS9UJ/YjOQtENpeHYZo4L77vU7wQJoUXWKZLu430JgbDU3JymUgU+HBdF1NVhappYg+UK3U0Wx2qUlU1GIYJx5nHmZkky2PYtkOG4+YJtneKdKKMFHTaKupHLXR7fXi+T/aj+gC5nQqtxUX0N02LjyYqdMNkkqKMcSornDEmcJlhPZk+SxbAMiCSuaKaYNEe5764sEgV5naLeLa8gmRml2cLe2FSJonL0O8PuWXZFPBlPYWZbkT1M5fwYqJ9y3YW9myuQFgf1I6QLxfAvDnXVAOmYTHJFZX5QYwpsJndRbvTJRI6fwaQRQeyMiFVxlPyCc8P6w1a/yw5KORkvvI6gVZryKRFJzzCqNsbIJ3J0noo8B0LbCZTjarT9YjdyVRFoVSB7wHFvA5NsfjH9++wlUowqd7s4nfT49evPsbDB2+J2a9ifELRdUswPMN87go7jzPTf0t04oml1chh7/Nz3m5UYQ66TFr9lMLL5Ta/eOEeLl+8Sc5rgpjJRP1v1gIxM7GGkhcV6lMdtXEHr3ol7sZjc2tpCTeu3edXLt3B3dtP4NgMlf0D1A4jjELmQ2Ji0mLp9Yeo7FXjgeCCiDAZkx49fYFqtcHVqbFwHgxlJFOZs7YjeziH4ynNmogNyL5bKEdYK4qYw0n4HP+Rch6f8HWsJdLEdOukgzcrH7Ca/IHv2yVxxmA7ToTjdp4qF8o1bS6w9thfvvfH2HgRUzcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tax Manager",
          "title": "Tax Manager",
          "src": "/static/ced751c7c36dd6738f9a6be30a1a94a9/d9199/sv-brand-1.png",
          "srcSet": ["/static/ced751c7c36dd6738f9a6be30a1a94a9/8ff5a/sv-brand-1.png 240w", "/static/ced751c7c36dd6738f9a6be30a1a94a9/e85cb/sv-brand-1.png 480w", "/static/ced751c7c36dd6738f9a6be30a1a94a9/d9199/sv-brand-1.png 960w", "/static/ced751c7c36dd6738f9a6be30a1a94a9/07a9c/sv-brand-1.png 1440w", "/static/ced751c7c36dd6738f9a6be30a1a94a9/29114/sv-brand-1.png 1920w", "/static/ced751c7c36dd6738f9a6be30a1a94a9/f24d4/sv-brand-1.png 2053w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABfklEQVQoz32T6XaCMBCFff9ns0+g7VGwyBYIhCTs07nRsNTWH3MmZPnmzsLhdPokrS1Z25Exq7VtT30/UtcNiw/DiM7nLwqC2+6cbcaabT4EQURSNs6qavUIMAzTDhrHGV0uIfuUg9rt2Qqsa+0UeYXW9otKrVvSpnUPARdCUprmDphlglUu6lfgOE6sSlHTWKqVIdUYVqgJgWCK9+Z5dkClNEVRzOfKfW/KsQL7fqCyVAyyVDEAYOw9a+NsmmYax5mKQtLx+EFJkrlvf75L2bYd3b5zTkFyKoXzoqie6rRTDKBXeL2GHNT8rxDdiu6CclFzfSTleUV3BqMx6D7Mp1zXjevwtvMPP+6BdwAZlKTl0zNYVGS4UXgEIGqN2qEpXt2fwI7rFUWCMgbFSelSTtgDjtoi4EPh6LqOadjM3ysQKS1jwiOy9499wPDA8D7uvwUmiXCR0V1chscsQgnU+Ye+KVJW71OWPHPSzZ1xMMxd8wRDkf/FYFj/+uVegD9GPaHBHKyEEwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "All Payment",
          "title": "All Payment",
          "src": "/static/62daad288426462653ad69f987648f2c/d9199/sv-brand-2.png",
          "srcSet": ["/static/62daad288426462653ad69f987648f2c/8ff5a/sv-brand-2.png 240w", "/static/62daad288426462653ad69f987648f2c/e85cb/sv-brand-2.png 480w", "/static/62daad288426462653ad69f987648f2c/d9199/sv-brand-2.png 960w", "/static/62daad288426462653ad69f987648f2c/07a9c/sv-brand-2.png 1440w", "/static/62daad288426462653ad69f987648f2c/29114/sv-brand-2.png 1920w", "/static/62daad288426462653ad69f987648f2c/d6eff/sv-brand-2.png 2855w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACbElEQVQoz4WTz0sUYRjHl/AfKOrQoUN1kMAOHaIunjoGQVCXLkElQQglhdnBDhEorRBSsVSieOhQYIe9aMJqZv5YtZSoxdo0El133XXX/TEz78y88356Zxdd7QcdvjwvM7yf+Tzv805gfj5OPL5IOp1FCImw3ErVsW0Xx5HlbK5tW2492xa1WQPCFtiOjWkVAUvH03HLcaUPdrbB3L/BdgJbm5/Tfq+b0UiGUEeaseESc9Mu428dNnISyxI6VeA/oFXg1Ytt3GzsYCCc4HH7BiODJWajMBZRZNe1petbuv+zrAITqyskUymyubRu08ZxLQ0wdLsC6XlbVlvZYapTWSun8k4FstkCuVyBYsEknzco6FrIV9amIf4w2jkoWf6Ab1YZlusD86RSuXJbUlaMpFSUSiZrmRxFw9LWnh6aQ8m0KyAhME2jbGgLPVDDqBp++PiNnt4BJqMxPE/pzRKpAQXbJlJaYai4zJplMZX2GEpK1gx9A5wZPDGgzzeJ/BFDzb1RcnURLakCnZ09HDxcz4PgMw2EdGYDQ7edsgyakhM0pyaJF4uEPkFrVBFflyC6oNCE62mJ/tfQcV3JqWE9gTLwCbW1RwkGH+oWFMuJDMWcwU8jz7HYS05+fcVMdp2GPof6LpPpJQc2GhFLddpoFPt+G86JI8p50YvjA/1robSZX/3zEzr+3Y6nU+zvvMGh0C1G4gucaomy98IgQ7NJ5sfP0t9bQyIZ5ktDC4M1e9RC8JG/TQV+n5z/Z6BgIZFg35UzHLh2jvefYxw//5RddXeJTHynr/s0ty8HiMXChC+1ciewW71rD+lrh/oFHRlgC5AwPkMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mobile Tax",
          "title": "Mobile Tax",
          "src": "/static/5abaab579dff542a3b0fbe6c4a8967c4/d9199/sv-brand-3.png",
          "srcSet": ["/static/5abaab579dff542a3b0fbe6c4a8967c4/8ff5a/sv-brand-3.png 240w", "/static/5abaab579dff542a3b0fbe6c4a8967c4/e85cb/sv-brand-3.png 480w", "/static/5abaab579dff542a3b0fbe6c4a8967c4/d9199/sv-brand-3.png 960w", "/static/5abaab579dff542a3b0fbe6c4a8967c4/07a9c/sv-brand-3.png 1440w", "/static/5abaab579dff542a3b0fbe6c4a8967c4/29114/sv-brand-3.png 1920w", "/static/5abaab579dff542a3b0fbe6c4a8967c4/64289/sv-brand-3.png 2838w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section name="Next" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <Box bg={({
                theme
              }) => theme.greenTransparent} center mdxType="Box">
          <div style={{
                  marginBottom: designTokens.space[3],
                  width: '100%',
                  maxWidth: '104px',
                  margin: 'auto'
                }}>
            <ImageWrap srcName="soleventure-example-icon" mdxType="ImageWrap" />
          </div>
          <h5>Upcoming</h5>
          <p>This project is still a work in progress, so check back for more updates</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      